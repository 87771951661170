import { useEffect, useState } from 'react'
import { Button } from '@truepill/react-capsule'
import { hasPriorAuthRejectCodes } from '@truepill/tpos-data-util'
import { useHistory, useLocation, useMutation, useReactiveVar } from '@truepill/tpos-react-router'
import type { PriorAuthorizationActionButton } from '@truepill/tpos-types'
import {
  CopayTriageReasons,
  TriageReasons,
  UserRoles,
  PriorAuthorizationStatus,
  LaunchDarkly,
  CopayStatus,
} from '@truepill/tpos-types'
import { SaveButton } from 'components/ActionButton'
import AuthLimited from 'components/AuthLimited'
import { ButtonsContainer, ModalHeader, ModalWrapper } from 'components/Modal'
import { INITIATE_PA, SET_COPAY_IN_PA, SET_ORDER_IN_PA } from 'gql'
import { claimsRequest } from 'hooks/navigation/useClaim'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useGetNextAdjudication from 'hooks/useGetNextAdjudication'
import useGetNextTriage from 'hooks/useGetNextTriage'
import { useClient, useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToFulfillment } from 'routes'
import styled from 'styled-components'
import type { ClaimsRequest, Fill, Prescription, PriorAuthorization, QueueItem } from 'types'
import { CopayTriageReasonMap, TriageReasonMap, getPrescriberAddressWithFax, isOrder } from 'utils'

type InitiatePriorAuthorizationProps = {
  item: QueueItem
  fill: Fill
  prescription: Prescription
}

/** This component must only handle redirection logic */
const Redirector = ({ item, redirect = false }: { item: QueueItem; redirect?: boolean }) => {
  const location = useLocation()
  const history = useHistory()
  const { getNextOrder: getNextAdjudication } = useGetNextAdjudication()
  const { getNextOrder: getNextTriage } = useGetNextTriage()
  const {
    currentLocation: { queryMap },
  } = usePlusClient()
  useGetNextAdjudication()

  useEffect(() => {
    if (redirect) {
      if (isOrder(item)) {
        const fromTriage = location.pathname.includes(`/fulfillment/${FulfillmentQueueName.Triage}`)
        if (queryMap.workflow === 'queue') {
          fromTriage ? getNextTriage() : getNextAdjudication()
        } else {
          history.push(
            goToFulfillment({
              fulfillmentQueueName: fromTriage ? FulfillmentQueueName.Triage : FulfillmentQueueName.Adjudication,
              search: '',
            }),
          )
        }
      } else {
        history.push(
          goToFulfillment({
            fulfillmentQueueName: FulfillmentQueueName.Copay,
            search: `?copayStatus=${queryMap.copayStatus || 'Pending'}`,
          }),
        )
      }
    }
  }, [redirect, item, location.pathname, queryMap, getNextTriage, getNextAdjudication, history])
  return <></>
}

const updateToPriorAuthorizationStatus = async ({
  item,
  fill,
  onOrder,
  onCopay,
  onError,
}: {
  item: QueueItem
  fill: Fill
  onOrder?: (item: QueueItem, fill: Fill) => Promise<void>
  onCopay?: (item: QueueItem, fill: Fill) => Promise<void>
  onError?: (e: { message: string }) => void
}): Promise<boolean> => {
  try {
    if (isOrder(item)) {
      await onOrder?.(item, fill)
    } else {
      await onCopay?.(item, fill)
    }
    return true
  } catch (e) {
    onError?.(e as { message: string })
    return false
  }
}

const InitiatePriorAuthorizationModal = ({
  pa,
  updatePA,
}: {
  pa: PriorAuthorization
  updatePA: () => Promise<boolean>
}): JSX.Element => {
  const { dismissModal } = useModalContext()
  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()

  return (
    <ModalWrapper id="UpdatePatientStatusModal">
      <ModalHeader>
        <h1>Confirm PA Required</h1>
      </ModalHeader>
      <SubTitleContainer>
        <SubTitle>This medication reject may be able to be resolved without a PA.</SubTitle>
        <SubTitle>If PA is still required, please click the button below to initiate.</SubTitle>
      </SubTitleContainer>
      <ButtonsContainer>
        <SaveButton
          isModal
          label={'Initiate PA'}
          onClick={async () => {
            if (await updatePA()) {
              if (pa.status === PriorAuthorizationStatus.ManualStart) {
                const startActionButton = pa.actions.find((action: PriorAuthorizationActionButton) => {
                  return action.buttonLabel === 'Start'
                })

                if (startActionButton) {
                  showSuccessToast('Initiating Prior authorization')
                  const win = window.open(startActionButton.url, '_blank')
                  win?.focus()
                  dismissModal()
                } else {
                  showErrorToast('Unexpected error. Unable to find url to start the PA')
                  dismissModal()
                }
              }
            }
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const InitiatePriorAuthorization = ({ prescription, item, fill }: InitiatePriorAuthorizationProps): JSX.Element => {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const { showModal } = useModalContext()
  const { client: ldClient } = useClient()
  const claimsRequestObject = useReactiveVar(claimsRequest) as ClaimsRequest
  const [redirectAfterSuccess, setRedirectAfterSuccess] = useState(false)
  const [setCopayInPriorAuth] = useMutation(SET_COPAY_IN_PA)
  const [setOrderInPriorAuth] = useMutation(SET_ORDER_IN_PA)
  const updatePA = () =>
    updateToPriorAuthorizationStatus({
      item,
      fill,
      onOrder: async (item, fill) => {
        await setOrderInPriorAuth({ variables: { orderId: item._id, fillId: fill._id } })
      },
      onCopay: async item => {
        await setCopayInPriorAuth({ variables: { copayRequestId: item._id } })
      },
      onError: e => showErrorToast('Unexpected error: ' + e.message),
    })

  const [initiatePriorAuthorization] = useMutation(INITIATE_PA, {
    onCompleted({ initiatePA }) {
      if (initiatePA.status === PriorAuthorizationStatus.ManualStart) {
        showModal(() => <InitiatePriorAuthorizationModal pa={initiatePA} updatePA={updatePA} />)
      } else {
        updatePA().then(() => {
          showSuccessToast('Prior authorization initiated successfully.')
        })
        setRedirectAfterSuccess(true) // trigger redirection
      }
    },
    onError(err) {
      showErrorToast(err.message)
    },
  })

  const submitPA = () => {
    let otherTriages = undefined
    if (isOrder(item)) {
      otherTriages = item.triages.find(
        ({ endDate, reason }) =>
          !endDate && TriageReasonMap[reason] !== TriageReasons.AdjudicationTriageReasons.PriorAuthorizationRequired,
      )
    } else {
      otherTriages = item.triages.find(
        ({ endDate, reason }) =>
          !endDate && CopayTriageReasonMap[reason] !== CopayTriageReasons.PriorAuthorizationNeeded,
      )
    }

    if (otherTriages) {
      showErrorToast('Other active triages found, please resolve first.')
      return
    }

    const claim = fill.claims[fill.claims.length - 1]
    const rejectCodes = claim?.received?.groups?.[0]?.response?.rejectCodes
    const rejectCode = (rejectCodes?.map((rc: { rejectCode: string }) => rc.rejectCode) || []).join(',')

    //"claim rejected" should only be when the claim rejects for multiple reject reasons or rejectCodeTranslated is empty
    const rejectMessage =
      rejectCodes?.length === 1 && rejectCodes[0]?.rejectCodeTranslated
        ? rejectCodes[0].rejectCodeTranslated
        : 'claim rejected'

    const address = getPrescriberAddressWithFax(prescription)
    initiatePriorAuthorization({
      variables: {
        rxNumber: prescription.rxNumber,
        npi: fill.location.npi,
        prescriptionId: prescription._id,
        claimId: claim._id,
        fillId: fill._id,
        locationId: fill.locationId,
        customerId: item.customerId,
        prescriberId: prescription.prescriberId,
        claimEdi: claim.sent.edi,
        rejectCode,
        rejectMessage,
        prescriberFax: address?.fax,
        medicationName: prescription.name,
        patientId: prescription.patientId,
        orderId: isOrder(item) ? item._id : undefined,
        copayRequestId: isOrder(item) ? undefined : item._id,
      },
    })
  }

  useEffect(() => {
    if (ldClient && prescription.customer?.legacyId) {
      ldClient.identify({
        key: prescription.customer?.legacyId.toString(),
      })
    }
  }, [ldClient, prescription.customer?.legacyId])

  const cmmPriorAuthFlag = useFlag(LaunchDarkly.FeatureFlags.COVER_MY_MEDS_PRIOR_AUTH)
  if (!cmmPriorAuthFlag) {
    return <></>
  }

  //Only display when the most recent claim reject codes include 70, 75, 76, MR, AG, A5, A6, 7X, 7Y, 9G, 608, 60, 80, 66, 64, AJ, 61, 828
  let claimsSubmissionResponse = null
  if (claimsRequestObject) {
    claimsSubmissionResponse = claimsRequestObject.claimsSubmissionResponse
  }

  const copayFill = !isOrder(item) ? item.copayRequestFills[item.copayRequestFills.length - 1] : undefined
  const copayFillCompletedOrRejected = !!(
    copayFill?.status && [CopayStatus.Rejected, CopayStatus.Complete].includes(copayFill.status)
  )

  const primaryClaim = fill.claims[0]
  const primaryRejectCodes = primaryClaim?.received?.groups?.[0]?.response?.rejectCodes

  // Init PA button should be displayed on the Completed and Rejected copay pages only if the primary claim is rejected for a PA-related reason
  const showBtn =
    (hasPriorAuthRejectCodes(claimsSubmissionResponse?.rejectionMessages) && !copayFillCompletedOrRejected) ||
    (copayFillCompletedOrRejected && hasPriorAuthRejectCodes(primaryRejectCodes))

  if (showBtn) {
    return (
      <InitiatePriorAuthorizationContainer>
        <AuthLimited roles={[UserRoles.Technician, UserRoles.Pharmacist, UserRoles.LeadPharmacist, UserRoles.Admin]}>
          <Redirector item={item} redirect={redirectAfterSuccess} />
          <Button style={{ padding: '8px' }} variant="primary-outline" onClick={() => submitPA()}>
            Initiate PA
          </Button>
        </AuthLimited>
      </InitiatePriorAuthorizationContainer>
    )
  }

  return <></>
}

const InitiatePriorAuthorizationContainer = styled.div`
  text-align: right;
  padding: 0.5rem;
`

const SubTitleContainer = styled.div`
  margin: 1rem 0 1rem 0;
`

const SubTitle = styled.p`
  margin-bottom: 0.5rem;
  line-height: 1rem;
`

export default InitiatePriorAuthorization
